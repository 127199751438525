import React from 'react'
import "./estimates.css";

const Estitmates = () => {
  return (
    <>
        {/* banner */}
        <div className='top1_banner'> 
        <h1>  Professional Plumbing Estimations and Procurements. </h1> 
        <p>We offer Estimation services for all project sizes.</p>
        </div>
        {/* estimate image example and text content */}
        <div className='main-img-text1'>
         
          {/* text- aside  */}
          <div className='main-texts'>  
              <h2>In Four Simple Steps, Get your plumbing materials delivered.</h2>
              <p>
                <ul>
                  <li>Provide us with Estimates prepared by the plumber and necessary information about the site.</li>
                  <li>We provide you with a Pro-Forma Invoice for the materials.</li>
                  <li>Make payments upon receipt of the Pro-Forma Invoice.</li>
                  <li>We deliver materials to your work site or doorstep.</li>
                </ul>
              </p>
          </div>
          <div className='link-up1'>
          Contact Us now  <a href='/contact'> <button >Contact Page</button></a>
          </div>
        </div>
        {/* middle banner */}
        <div>

        </div>
        {/* info boxes */}
        <div className='info1-boxs'>
          {/* box 1 */}
          <div className='box1-ds'>
            <img className='box1-img' src='../pics/yeps-supply-2.png' alt=''/>
          </div>
          {/* box 2 */}
          <div className='box1-ds'>
          <img className='box1-img' src='../pics/yeps-supplies.png' alt='hello'/>
          </div>
          {/* box 3 */}
          <div className='box1-ds'>
          <img className='box1-img' src='../pics/yeps-plumbing-coiled-pipe.webp' alt=''/>
          </div>
          {/* box 4 */}
          <div className='box1-ds'>
          <img className='box1-img' src='../pics/yeps-mat.jpg' alt=''/>
          </div>
        </div>
    </>
  )
}

export default Estitmates