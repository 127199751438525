import React from "react";
import Fade from 'react-reveal/Fade';
import RubberBand from 'react-reveal/RubberBand';
import "./Home.css";
// import Cards from "./Cards.js";
// import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import EngineeringIcon from '@mui/icons-material/Engineering';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Zoom } from "react-reveal";
import CountUp from 'react-countup';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";



function Home() {


  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 5 // optional, default to 1.
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  return (
    
    <div className="main-cont">
    <div className='hero-con'>
     {/* <div className="hero-bg">
     <video className='videobg' autoPlay loop muted src='../../vids/vp.mp4' />
     </div>
     <div className='hero-content'>
      <div className='the-H1'><Zoom><h1>WELCOME</h1></Zoom> </div>
      <RubberBand><div className='catch-p'>Start a New Project With Us, Fix Problems With Long Lasting Solutions<br/>&<br/>Get The Best Bespoke Designs That Comes With a Futuristic Touch</div></RubberBand>
     </div> */}
{/* -------------------- */}
     <div style={{
          margin: "10px",
          width: '100%'
        }}
        
         >
         
  <Carousel 
        responsive={responsive}
         showDots={true}
         swipeable={true}
          draggable={true}
          infinite={true}
          arrows={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        // deviceType={this.props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
         >
    {/* <div  >
      <img style={{width: '100%',height:'100%'}} src="../../pics/22.jpg" title='yeps plumbing projects'  alt="yeps plumbing projects"/>
    </div> */}
    <div >
      <img style={{width: '100%',height:'100%', display:'flex', justifyContent:'center'}}  src="../../pics/24.jpg" title='yeps plumbing projects'  alt="yeps plumbing projects"/>
      <div  class="
      position-absolute 
       top-0 start-0 w-100 h-100 d-flex align-items-center" >
                    <div class="container">
                        <div class="row justify-content-start">
                            <div class="col-10 col-lg-8" style={{backgroundColor:'rgba(0, 0, 0, .4)',padding:'5px'}}>
                                <h5 style={{backgroundColor:'rgba(0, 0, 0, .4)',borderTop:'2px solid white',borderBottom:'2px solid white'}} class="text-white text-uppercase mb-3 animated slideInDown">Unique Service Delivery</h5>
                                <h1 class="display-2 text-white animated slideInDown mb-4">Efficient Residential Plumbing Services</h1>
                               
                                
                            </div>
                        </div>
                    </div>
        </div>
    </div>
    <div >
      <img style={{width: '100%',height:'100%'}}  src="../../pics/25.jpg" title='yeps plumbing projects'  alt="yeps plumbing projects"/>
      
    </div>
    <div >
      <img style={{width: '100%',height:'100%'}}  src="../../pics/26.jpg" title='yeps plumbing projects'  alt="yeps plumbing projects"/>
      <div  class="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center" >
                    <div class="container">
                        <div class="row justify-content-start">
                            <div class="col-10 col-lg-8" style={{backgroundColor:'rgba(0, 0, 0, .4)',padding:'5px'}}>
                                <h5 style={{backgroundColor:'rgba(0, 0, 0, .4)',borderTop:'2px solid white',borderBottom:'2px solid white'}} class="text-white text-uppercase mb-3 animated slideInDown">Expert Analysis</h5>
                                <h1 class="display-3 text-white animated slideInDown mb-4">Analysing Project requirements </h1>
                               
                                
                            </div>
                        </div>
                    </div>
        </div>
    </div>
    <div >
      <img style={{width: '100%',height:'100%'}}  src="../../pics/27.jpg" title='yeps plumbing projects'  alt="yeps plumbing projects"/>
    </div>
    <div >
      <img style={{width: '100%',height:'100%'}}  src="../../pics/28.jpg" title='yeps plumbing projects'  alt="yeps plumbing projects"/>
      <div  class="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center" >
                    <div class="container">
                        <div class="row justify-content-start">
                            <div class="col-10 col-lg-8" style={{backgroundColor:'rgba(0, 0, 0, .4)',padding:'5px'}}>
                                <h5 style={{backgroundColor:'rgba(0, 0, 0, .4)',borderTop:'2px solid white',borderBottom:'2px solid white'}} class="text-white text-uppercase mb-3 animated slideInDown">Proffesional Standards</h5>
                                <h1 class="display-3 text-white animated slideInDown mb-4">General and High Plumbing Standards </h1>
                               
                                
                            </div>
                        </div>
                    </div>
        </div>
    </div>
    <div >
      <img style={{width: '100%',height:'100%'}}  src="../../pics/29.jpg" title='yeps plumbing projects'  alt="yeps plumbing projects"/>
    </div>
    <div >
      <img style={{width: '100%',height:'100%'}}  src="../../pics/33.jpg" title='yeps plumbing projects'  alt="yeps plumbing projects"/>
      <div  class="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center" >
                    <div class="container">
                        <div class="row justify-content-start">
                            <div class="col-10 col-lg-8" style={{backgroundColor:'rgba(0, 0, 0, .4)',padding:'5px'}}>
                                <h5 style={{backgroundColor:'rgba(0, 0, 0, .4)',borderTop:'2px solid white',borderBottom:'2px solid white'}} class="text-white text-uppercase mb-3 animated slideInDown">Large Scale Projects</h5>
                                <h1 class="display-3 text-white animated slideInDown mb-4">
                                Modern Approach to Scalability </h1>
                               
                                
                            </div>
                        </div>
                    </div>
        </div>
    </div>
    <div >
      <img style={{width: '100%',height:'100%'}}  src="../../pics/34.jpg" title='yeps plumbing projects'  alt="yeps plumbing projects"/>
    </div>
    <div >
      <img style={{width: '100%',height:'100%'}}  src="../../pics/35.jpg" title='yeps plumbing projects'  alt="yeps plumbing projects"/>
      <div  class="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center" >
                    <div class="container">
                        <div class="row justify-content-start">
                            <div class="col-10 col-lg-8" style={{backgroundColor:'rgba(0, 0, 0, .4)',padding:'5px'}}>
                                <h5  style={{backgroundColor:'rgba(0, 0, 0, .4)',borderTop:'2px solid white',borderBottom:'2px solid white'}} class="text-white text-uppercase  mb-3 animated slideInDown">Maintenance & Repairs</h5>
                                <h1 class="display-3 text-white animated slideInDown mb-4">
                                Easy access to Maintenance and Repair Works </h1>
                                
                                
                            </div>
                        </div>
                    </div>
        </div>
    </div>
    <div >
      <img style={{width: '100%',height:'100%'}}  src="../../pics/36.jpg" title='yeps plumbing projects'  alt="yeps plumbing projects"/>
    </div>
  </Carousel>;
 
      </div>

   
      {/* -----------end of carousel-------- */}
    </div>
    
    {/* <!-- About Start --> */}
    <div class="container-xxl py-5" className="para-1">
        <div class="container">
            <div class="row g-5">
                <div class="col-lg-6 " >
                    <h1 class="mb-4">We Are The Most Trusted Plumbing Company in Ghana.</h1>
                    <p class="mb-4 " className="shadow-1"><span  style={{ fontWeight:'900'}}>W</span><span style={{fontWeight:'bolder'}}>ith over half a decade experience in plumbing services, our team of experts are here to help you with all your plumbing needs.<br/> Whether it is a new or stalled project, a complicated problem or one that just needs a quick fix, we’ll help you figure it out and fix it at the most affordable cost.</span></p>
                    <p className="shadow-01" ><i class="fa fa-check text-success me-3"></i>Residential & commercial plumbing</p>
                    <p className="shadow-01" ><i class="fa fa-check text-success me-3"></i>Quality services at affordable prices</p>
                    <p  className="shadow-01" ><i class="fa fa-check text-success me-3"></i>Immediate 24/ 7 emergency services</p>
                    <a className="callink" href="tel:+233 24 957 5016">
                    <div class="bg-primary d-flex align-items-center p-4 mt-5">
                        <div class="d-flex flex-shrink-0 align-items-center justify-content-center bg-white"
                        //  style="width: 60px; height: 60px;"
                        style={{ width: "60px", height: '60px', textDecoration:'none'}}
                         >
                            <i class="fa fa-phone-alt fa-2x text-primary " style={{ textDecoration:'none' }}></i>
                        </div>
                        <div class="ms-3">
                            <p class="fs-5 fw-medium mb-2 text-white">Emergency 24/7</p>
                            <h3 class="m-0 text-warning">+233 24 957 5016</h3>
                        </div>
                    </div>
                        </a>
                </div>
                <div class="col-lg-6 pt-4" 
                style={{minHeight:'500px'}}
                >
                    <div class="position-relative h-100 " >
                        <img class="position-absolute img-fluid w-100 h-100" src="../../pics/pw.jpg"
                        //  style="object-fit: cover; padding: 0 0 50px 100px;" 
                        style={{ objectFit: "cover", padding: '0 0 50px 100px'}}
                         alt=""/>
                        <img class="position-absolute start-0 bottom-0 img-fluid bg-white pt-2 pe-2 w-50 h-50" src="../../pics/np3.jpg" 
                        // style="object-fit: cover;" 
                        style={{ objectFit: "cover"}}
                        alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- About End --> */}
    {/* project images */}
    <div className="drops">
    {/* top */}
      <div>
      <img
        style={{display: "flex", width: "100%", objectFit: "cover"}}
        src="../../pics/yps-plumb.jpg"
        alt="reload"
      />
      </div>
      {/* down two */}
      <div className="drops-two">
        <div>
        <img
        style={{display: "flex", width: "100%", objectFit: "cover"}}
        src="../../pics/33.jpg"
        alt="reload"
      />
        </div>
        <div>
        <img
        style={{display: "flex", width: "100%", objectFit: "cover"}}
        src="../../pics/26.jpg"
        alt="reload"
      />
        </div>
      </div>
    </div>
      
     <div className="para-2">
      <div  style={{backgroundColor:''}}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="HSLA(204,100%,50%,1)" fill-opacity="1" d="M0,96L80,112C160,128,320,160,480,154.7C640,149,800,107,960,96C1120,85,1280,107,1360,117.3L1440,128L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path></svg>
      </div>
      <div className="whyH1"><h1>WHY CHOOSE YEPS PLUMBING SERVICES ?</h1></div>
      <div className="threeCards">
      {/* card stuffs go here */}
      {/* card 1 */}
      <div
        class="card" className="card">
        <div class="card-header" style={{width:'100%',display:'flex',justifyContent:'space-evenly'}}>
        <div>
          <img
            style={{width: "100%", height: "100%"}}
            src="./pics/reliability.jpeg"
            alt='yepsplumbing.com'/></div>
        </div>
        <div
          class="card-body">
          <blockquote class="blockquote mb-0">
          
            <p>
            <span>Reliability </span> : YEPS Plumbing Service is reliable and available to respond to customers' needs in a timely manner, whether it's for emergency repairs or scheduled maintenance. We thrive to deliver as and when our clients require our services
            </p>
            <footer class="blockquote-footer">
              <a href="tel:+233 24 957 5016">+233 24 957 5016</a>
              <cite title="Source Title"> Go ahead and give us a call </cite>
            </footer>
          </blockquote>
        </div>
       
      </div>
        {/* - card 2*/}
         {/* - */}
         <div
        class="card">
        <div class="card-header" style={{width:'100%',display:'flex',justifyContent:'space-evenly'}}>
        <div>
          <img
            style={{width: "100%", height: "100%"}}
            src="./pics/Customer.jpg"
            alt='yepsplumbing.com'/></div>
        </div>
        <div
          class="card-body">
          <blockquote class="blockquote mb-0">
            <p>
            

<span>Customer service </span>: YEPS Plumbing Service has a friendly and helpful staff who are willing to go the extra mile to ensure that customers are satisfied with their services. This can include providing clear communication, offering helpful advice, and following up to ensure that any issues have been resolved to the customer's satisfaction.
            </p>
            <footer class="blockquote-footer">
            <a href="https://web.facebook.com/Yeps-Plumbing-Services-102870238530503" class="me-4 text-reset">
        <i class="fab fa-facebook-f"></i>
      </a>
              <cite title="Source Title">Like and Follow Us</cite>
            </footer>
          </blockquote>
        </div>
       
      </div>
      {/* --card 3 */}
         <div
        class="card">
        <div class="card-header" style={{width:'100%',display:'flex',justifyContent:'space-evenly'}}>
        <div>
          <img
            style={{width: "100%", height: "100%"}}
            src="./pics/exp.jpg"
            alt='yepsplumbing.com'/></div>
            </div>
        <div
          class="card-body">
          <blockquote class="blockquote mb-0">
            <p>
<span>Expertise </span>: YEPS Plumbing Service has a team of skilled and experienced plumbers who are knowledgeable about a wide range of plumbing systems and can provide high-quality workmanship.<br/>
            </p>
            <footer class="blockquote-footer">
            <a href="https://www.instagram.com/yepsplumbingservices" class="me-4 text-reset">
        <i class="fab fa-instagram"></i>
      </a>
              <cite title="Source Title">Let's get interactive </cite>
            </footer>
          </blockquote>
        </div>
        
       
      </div>
       
        {/* - */}
        </div>
        
        {/* ------------ */}
    <div className="projects">
      <div className="coun-t">
        <div><h3>Projects <CountUp enableScrollSpy={true} duration={2} end={900} />+</h3></div>
        <div className="coun-t-icon"><EngineeringIcon/></div>
      </div>
      <div className="coun-t">
        <div><h3>Satisfied Clients <CountUp enableScrollSpy={true} duration={2} end={700} />+</h3></div>
        <div className="coun-t-icon"><InsertEmoticonIcon/></div>
      </div>
      <div className="coun-t">
        <div><h3>Growth <CountUp enableScrollSpy={true} duration={3} end={80} />% </h3></div>
        <div className="coun-t-icon"><TrendingUpIcon/></div>
      </div>
    </div>
    {/* ==== */}
      <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fill-opacity="1" d="M0,288L30,250.7C60,213,120,139,180,117.3C240,96,300,128,360,165.3C420,203,480,245,540,266.7C600,288,660,288,720,282.7C780,277,840,267,900,245.3C960,224,1020,192,1080,202.7C1140,213,1200,267,1260,282.7C1320,299,1380,277,1410,266.7L1440,256L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"></path></svg>
      </div>
      </div>
      {/* classic video here */}
      <div className='hero-con'>
     <div className="hero-bg">
     <video className='videobg' autoPlay loop muted src='../../vids/classic.mp4' />
     </div>
     <div className='hero-content'>
      <div className='catch-p'>Experience<br/>Class & Elegance</div>
     </div>
    </div>
    {/* video ends here */}
    <div className="brands">
      <h2>Brands </h2>
      <h2>Incoporated In Our Works</h2>
    
          <Carousel 
  swipeable={true}
  draggable={true}
  showDots={false}
  responsive={responsive}
  // ssr={true} 
  infinite={true}
  autoPlay={true}
  autoPlaySpeed={2000}
  keyBoardControl={true}
  customTransition="all .5"
  transitionDuration={500}
  containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile",'desktop']}
  // deviceType={this.props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px "
>
  <div className=''><img className='caros-img' src='../../pics/interplast.jpg' alt='yeps plumbing services' title='Yeps Plumbing Top brands'/></div>
        
        <div className=''><img className='caros-img' src='../../pics/jaquar.jpg' alt='yeps plumbing services' title='Yeps Plumbing Top brands'/></div>
               
        <div className=''><img className='caros-img' src='../../pics/dps.png' alt='yeps plumbing services' title='Yeps Plumbing Top brands'/></div>
               
        <div className=''><img className='caros-img' src='../../pics/era.jpg' alt='yeps plumbing services' title='Yeps Plumbing Top brands'/></div>
      
        <div className=''><img className='caros-img' src='../../pics/eraglue.jpg' alt='yeps plumbing services' title='Yeps Plumbing Top brands'/></div>
          </Carousel>
         
          </div>
       
    
    
      
    </div>
  );
}

export default Home;
