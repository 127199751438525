import React, { useState } from 'react';
import ReactWhatsapp from "react-whatsapp";
import './Contact.css'

function Contact() {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [wmessage, setWmessage] = useState('');

  const handleChange = (e) => {
    setWmessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const mailtoLink = `mailto:yepsplumbing@gmail.com?subject=Contact%20Form%20Submission&body=${encodeURIComponent(
      `Name: ${name}%0D%0AEmail: ${email}%0D%0AMessage: ${message}`
    )}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className="cc-m">
      <div className='f-h'>
      <div>
      <form onSubmit={handleSubmit}>
      <label htmlFor="name">Name:</label>
      <input
        type="text"
        id="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        className="input-field"
      />

      <label htmlFor="email">Email:</label>
      <input
        type="email"
        id="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="input-field"
      />

      <label htmlFor="message">Message:</label>
      <textarea
        id="message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        className="message-field"
      />

      <button type="submit" className="submit-button">Send Email</button>
    </form>
    </div>
    <div className='wn-h'>
    <label htmlFor="message">WhatsApp here now</label>
      <textarea
        id="wmessage"
        placeholder='Ask questions, describe your problem'
        value={wmessage}
        onChange={(e) => setWmessage(e.target.value)}
        className="message-field"
      />
      {/* <button className='whatsapp-bfutton'> */}
          <ReactWhatsapp number="+233249575016"  message= {`${wmessage}`} className='whatsapp-button' > Send <i style={{fontSize:'large'}} class="fa fa-send"></i> 
          </ReactWhatsapp> 
      {/* </button> */}
    </div>
    </div>

    <div className="cm">

      <div className="wa">
      <ReactWhatsapp number="+233249575016" style={{border: "none", backgroundColor: "transparent"}}>

        <div><img style={{height:'50px',width:'auto'}} src="https://www.freepnglogos.com/uploads/whatsapp-png-image-9.png" alt="yeps plumbing services whatsapp"/></div>
        </ReactWhatsapp> 
      </div>
      <div className="wa">
      <a href="https://web.facebook.com/Yeps-Plumbing-Services-102870238530503">
        <div><img style={{height:'50px',width:'auto'}} src="https://1000logos.net/wp-content/uploads/2021/04/Facebook-logo.png" alt="yeps plumbing services facebook"/></div>
        </a>
      </div>
      <div className="wa">
      <a href="https://www.instagram.com/yepsplumbingservices">
        <div><img style={{height:'50px',width:'auto'}} src="https://1000logos.net/wp-content/uploads/2017/02/Instagram-Logo.png" alt="yeps plumbing services instagram"/></div>
        </a>
      </div>
      <div className="wa"> 
      <a
            href="tel:+233 56 111 1716" >
        <div><img style={{height:'50px',width:'auto'}} src="https://i.pinimg.com/736x/84/4e/8c/844e8cd4ab26c82286238471f0e5a901.jpg" alt="yeps plumbing services call"/></div>
        </a>
      </div>
    </div>
    </div>
  );
}

export default Contact;
