import React from "react";
import Fade from 'react-reveal/Fade';
// import ReactWhatsapp from "react-whatsapp";
import FacebookIcon from "@mui/icons-material/Facebook";
import Footer from "../Footer";
import './About.css';

function About() {
  return (
    <div
      className="main-c"
      style={{
        // backgroundImage: "url(../pics/bg2.jpg)",
        display: "grid",
        justifyContent: "center",
        justifyItems: "center",
        alignItems: "center",
      }}>
      {/* -=-==- */}

      <Fade bottom>
        <h2
          style={{
            textAlign: "center",
            alignSelf: "center",
            color: "#606060",
            fontFamily: 'Bodoni Moda',
            paddingTop:'10px'
          }}>
          SERVICES OVERVIEW
        </h2>
      </Fade>
      <div className='service-cards'>
      <div class="card" styles={{maxWidth:'18rem'}}>
            <img className='cardImage' src="../../pics/pw.jpg" class="card-img-top" alt="toilet installation, yeps pluming service "title="Gas Pipeline Connections, yeps pluming service"/>
              <div class="card-body">
                <h5 class="card-title">Gas Pipeline Connections</h5>
                <p class="card-text">Safe Gas pipeline works and connections for homes and institutions...</p>
                <a href="#" class="btn btn-primary">Read More</a>
             </div>
          </div>
          <div class="card" styles={{maxWidth:'18rem'}}>
            <img className='cardImage' src="../../pics/koyo.jpg" class="card-img-top" alt="toilet installation, yeps pluming service" title="KOYO Machine Installation, yeps pluming service"/>
              <div class="card-body">
                <h5 class="card-title">KOYO Machine Installation</h5>
                <p class="card-text">Proffesional services for water connections into and out your KOYO machine...</p>
                <a href="#" class="btn btn-primary">Read More</a>
             </div>
          </div>
        
        <div class="card" styles={{maxWidth:'18rem'}}>
            <img className='cardImage' src="../../pics/bath2.jpg" class="card-img-top" alt="toilet installation, yeps pluming service" title="shower installation, yeps pluming service"/>
              <div class="card-body">
                <h5 class="card-title"> Shower & Jacuzzi Installation</h5>
                <p class="card-text">Fixing and repair of Marble stone Jacuzzi to controlled shower system ...</p>
                <a href="#" class="btn btn-primary">Read More</a>
             </div>
          </div>
        <div class="card" styles={{maxWidth:'18rem'}}>
            <img className='cardImage' src="../../pics/s2.jpg" class="card-img-top" alt="toilet installation, yeps pluming service" title="Water Pressure Control & Repairs, yeps pluming service"/>
              <div class="card-body">
                <h5 class="card-title">Water Pressure Control & Repairs</h5>
                <p class="card-text">Get expert advise on pressure control to ensure constant supply of water...</p>
                <a href="#" class="btn btn-primary">Read More</a>
             </div>
          </div>
        
          <div class="card" styles={{maxWidth:'18rem'}}>
            <img className='cardImage' src="../../pics/np9.jpg" class="card-img-top" alt="toilet installation, yeps pluming service" title="toilet installation, yeps pluming service"/>
              <div class="card-body">
                <h5 class="card-title">Toilet & Urinal Module Installation</h5>
                <p class="card-text">We install new and replace old toilet and urinal modules with experience ...</p>
                <a href="#" class="btn btn-primary">Read More</a>
             </div>
          </div>
        
      </div>
      {/* <div style={{
          margin: "10px",
        }}>
      <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="../../pics/22.jpg" title='yeps plumbing projects' class="d-block w-100" alt="yeps plumbing projects"/>
    </div>
    <div class="carousel-item">
      <img src="../../pics/24.jpg" title='yeps plumbing projects' class="d-block w-100" alt="yeps plumbing projects"/>
    </div>
    <div class="carousel-item">
      <img src="../../pics/25.jpg" title='yeps plumbing projects' class="d-block w-100" alt="yeps plumbing projects"/>
    </div>
    <div class="carousel-item">
      <img src="../../pics/26.jpg" title='yeps plumbing projects' class="d-block w-100" alt="yeps plumbing projects"/>
    </div>
    <div class="carousel-item">
      <img src="../../pics/27.jpg" title='yeps plumbing projects' class="d-block w-100" alt="yeps plumbing projects"/>
    </div>
    <div class="carousel-item">
      <img src="../../pics/28.jpg" title='yeps plumbing projects' class="d-block w-100" alt="yeps plumbing projects"/>
    </div>
    <div class="carousel-item">
      <img src="../../pics/29.jpg" title='yeps plumbing projects' class="d-block w-100" alt="yeps plumbing projects"/>
    </div>
    <div class="carousel-item">
      <img src="../../pics/33.jpg" title='yeps plumbing projects' class="d-block w-100" alt="yeps plumbing projects"/>
    </div>
    <div class="carousel-item">
      <img src="../../pics/34.jpg" title='yeps plumbing projects' class="d-block w-100" alt="yeps plumbing projects"/>
    </div>
    <div class="carousel-item">
      <img src="../../pics/35.jpg" title='yeps plumbing projects' class="d-block w-100" alt="yeps plumbing projects"/>
    </div>
    <div class="carousel-item">
      <img src="../../pics/36.jpg" title='yeps plumbing projects' class="d-block w-100" alt="yeps plumbing projects"/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
      </div>
      </div> */}

   
      {/* -----------end of carousel-------- */}

      {/* -=-- */}
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <div
          class="container"
          style={{ 
            display: "flex",
            alignSelf: "center",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <div
            // class="row row-cols-2"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Fade bottom>
              <div
                class="card"
                style={{
                  maxWidth: "20rem",
                  backgroundColor: "transparent",
                  margin: "10px",
                  fontSize: "large",
                  letterSpacing: "2px",
                }}>
                <ul class="list-group list-group-flush">
                  <h5>General Plumbing Services</h5>
                  <li class="list-group-item">Toilet Repairs</li>
                  <li class="list-group-item">Faucet Repairs</li>
                  <li class="list-group-item">General Plumbing Maintenance</li>
                  <li class="list-group-item">Poly Tank Installation</li>
                  <li class="list-group-item">Kitchen Cabinet & Sink Installation</li>
                  <li class="list-group-item">KOYO Machine Installation</li>
                  <li class="list-group-item">Poly Tank Interior Cleaning</li>
                  <li class="list-group-item">Low pressure water supply</li>
                  <li class="list-group-item">Shower & Jacuzzi installation</li>
                </ul>
                {/* <div class="card-footer">Many nore</div> */}
              </div>
            </Fade>

            {/* -- */}
            <Fade bottom>
              <div
                class="card"
                style={{
                  maxWidth: "20rem",
                  backgroundColor: "transparent",
                  margin: "10px",
                  alignSelf: "center",
                  fontSize: "large",
                  letterSpacing: "2px",
                }}>
                <ul class="list-group list-group-flush">
                  <h5>Underground Utilities </h5>
                  <li class="list-group-item">Repiping</li>
                  <li class="list-group-item">Sewer Cleaning</li>
                  <li class="list-group-item">Open Trench Sewer Repairs</li>
                  <li class="list-group-item">Leaking Floors & Walls</li>
                </ul>
                {/* <div class="card-footer">Leaking Floors</div> */}
              </div>
            </Fade>
          </div>
        </div>
      </div>
      {/* -=the info cards with icons */}
      <div
        style={{
          backgroundImage: "url(../pics/bg1.jpg)",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
        }}>
        <Fade bottom>
          <div className="box"
            style={{
              maxWidth: "20rem",
              margin: "20px",
              display:'flex',
              flexDirection:'column',
              justifyContent:'center',
            }}>
            <div style={{
              display:'flex',
              justifyContent:'center',
              }}>
              <img
                style={{width: "4rem", height: "5rem"}}
                src="./icons/fix-sink.png"
                alt="..."
              />
            </div>
            <div
              className="kard-body"
              style={{
                textAlign: "center",
                letterSpacing: "2px",
                fontSize: "larger",
                fontWeight: "bold",
              }}>
              <blockquote class="blockquote mb-0">
                <p>
                  Need A Quick Fix For Your Leaking Or Chocked Sink ? Is Your
                  Old Sink Due To Be Replaced, Do You Want To Change The Sink To
                  Match Your New Interior Design ?
                </p>
                <footer class="blockquote-footer">
                  <a href="tel:+233 24 957 5016">+233 24 957 5016</a>{" "}
                  <cite title="Call Us">Call Us </cite>
                </footer>
              </blockquote>
            </div>
          </div>
        </Fade>
        {/* =-=-one 1 is above */}
        <Fade bottom>
        <div className="box"
            style={{
              maxWidth: "20rem",
              margin: "20px",
              display:'flex',
              flexDirection:'column',
              justifyContent:'center',
            }}>
            <div style={{
              display:'flex',
              justifyContent:'center',
              }}>
              <img
                style={{width: "4rem", height: "5rem"}}
                src="./icons/hot-water.png"
                alt="..."
              />
            </div>
            <div
              className="kard-body" 
              style={{
                textAlign: "center",
                letterSpacing: "2px",
                fontSize: "larger",
                fontWeight: "bold",
              }}>
              <blockquote class="blockquote mb-0">
                <p>
                  We Are Experts In The Installation Of Water Temperature (Water
                  Heaters) Regulators For Your Bathrooms. We Sort Out The Best
                  Heaters On The Market For Your.
                </p>
                <footer class="blockquote-footer">
                  <a href=" https://web.facebook.com/Yeps-Plumbing-Services-102870238530503">
                    <FacebookIcon
                      style={{width: "3rem", height: "3rem", color: "#303030"}}
                    />
                  </a>
                  <cite title="Source Title">
                    {" "}
                    <a
                      href=" https://web.facebook.com/Yeps-Plumbing-Services-102870238530503"
                      style={{color: "#303030"}}>
                      Follow And like Our Facebook Page
                    </a>
                  </cite>
                </footer>
              </blockquote>
            </div>
          </div>
        </Fade>
        {/* -=-=third 3rd-= */}
        <Fade bottom>
        <div className="box"
            style={{
              maxWidth: "20rem",
              margin: "20px",
              display:'flex',
              flexDirection:'column',
              justifyContent:'center',
            }}>
            <div style={{
              display:'flex',
              justifyContent:'center',
              }}>
              <img
                style={{width: "4rem", height: "5rem"}}
                src="./icons/toilet-cleaner.png"
                alt="..."
              />
            </div>
            <div
              className="kard-body"
              style={{
                textAlign: "center",
                letterSpacing: "2px",
                fontSize: "larger",
                fontWeight: "bold",
              }}>
              <blockquote class="blockquote mb-0">
                <p>
                  We Fix Washroom Problems Including Clogged Toilets And Faulty
                  Cesterns.
                </p>
                <footer class="blockquote-footer">
                  <a href="tel:+233 24 957 5016">+233 24 957 5016</a>{" "}
                  <cite title="Book An Appointment">
                    Call Now To Book An Appointment{" "}
                  </cite>
                </footer>
              </blockquote>
            </div>
          </div>
        </Fade>

        {/* -=-=fourth 4th card */}
        <Fade bottom>
        <div className="box"
            style={{
              maxWidth: "20rem",
              margin: "20px",
              display:'flex',
              flexDirection:'column',
              justifyContent:'center',
            }}>
            <div style={{
              display:'flex',
              justifyContent:'center',
              }}>
              <img
                style={{width: "4rem", height: "5rem"}}
                src="./icons/bpipe.png"
                alt="..."
              />
            </div>
            <div
              className="kard-body"
              style={{
                textAlign: "center",
                letterSpacing: "2px",
                fontSize: "larger",
                fontWeight: "bold",
              }}>
              <blockquote class="blockquote mb-0">
                <p>
                  We Repair And Replace Broken Pipelines. Been It Water Pipes Or
                  Sewage Pipes. We Apply The State Of The Art Plumbing
                  Technology To Ensure The Problem Does Not Re-emerge.
                </p>
                <footer class="blockquote-footer">
                  <a href="tel:+233 24 957 5016">+233 24 957 5016</a>{" "}
                  <cite title="Call Us">Go ahead and give us a call </cite>
                </footer>
              </blockquote>
            </div>
          </div>
        </Fade>
        
<div>
        {/* -=end of cards */}
        <div className="labell">
      <h2>Stylish Projects</h2>
    </div>
      {/*  gallery */}

<div class="row">
  <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
    <img
      src="../../pics/top-v.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="yeps plumbing service"
    />

    <img
      src="../../pics/bath1.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Wintry Mountain Landscape"
    />
  </div>

  <div class="col-lg-4 mb-4 mb-lg-0">
    <img
      src="../../pics/s2.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Mountains in the Clouds"
    />

    <img
      src="../../pics/ik.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="yeps plumbing service"
    />
  </div>

  <div class="col-lg-4 mb-4 mb-lg-0">
    <img
      src="../../pics/bath3.png"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="yeps plumbing service"
    />

    <img
      src="../../pics/k.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="yeps plumbing service"
    />
  </div>
</div>
{/* end of gallery */}
</div>
      {/* add up for the down */}
      {/* footer */}
      </div>
      <div style={{margin: "10px", width: "100%",display:'flex', justifyContent:'center',}}>
        <Footer />
      </div>
      {/* end of line */}
    </div>
  );
}

export default About;
