import React from "react";
import "./Footer.css";
import ReactWhatsapp from "react-whatsapp";

function Footer() {
  return (
    <div className="mainF">
      {/* new start */}
      <div className="n-footer">
      {/* <!-- Footer --> */}
<footer class="text-center text-lg-start bg-light text-muted">
  {/* <!-- Section: Social media --> */}
  <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
    {/* <!-- Left --> */}
    <div class="me-5 d-none d-lg-block">
      <span>Get connected with us on social networks:</span>
    </div>
    {/* <!-- Left --> */}

    {/* <!-- Right --> */}
    <div >
      <a href="https://web.facebook.com/Yeps-Plumbing-Services-102870238530503" class="me-4 text-reset">
        <i style={{color:'black',fontSize:'larger',border:'1px solid black',padding:'2px',borderRadius:'5px'}} class="fab fa-facebook-f"></i>
      </a>
      <ReactWhatsapp number="+233249575016" style={{border: "none", backgroundColor: "transparent"}}>
      <a href={() => false} class="me-4 text-reset">
        <i style={{color:'black',fontSize:'x-large'}} class="fab fa-whatsapp"></i> 
      </a>
      </ReactWhatsapp>
      <a href="https://www.instagram.com/yepsplumbingservices" class="me-4 text-reset">
        <i style={{color:'black',fontSize:'x-large'}} class="fab fa-instagram"></i>
      </a>
      <a href="https://www.youtube.com/@yepsplumbing3605" class="me-4 text-reset">
        <i style={{color:'black',fontSize:'x-large'}} class="fab fa-youtube"></i>
      </a>
    </div>
    {/* <!-- Right --> */}
  </section>
  {/* <!-- Section: Social media --> */}

  {/* <!-- Section: Links  --> */}
  <section class="">
    <div class="container text-center text-md-start mt-5">
      {/* <!-- Grid row --> */}
      <div class="row mt-3">
        {/* <!-- Grid column --> */}
        <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
          {/* <!-- Content --> */}
          <h6 class="text-uppercase fw-bold mb-4">
            YEPS PLUMBING SERVICES
          </h6>
          <p>
            Contact us for enquiries and discounted services. Our service delivery spans nationwide with over 500+ projects completed.
          </p>
        </div>
        {/* <!-- Grid column --> */}
       
        {/* <!-- Grid column --> */}
        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          {/* <!-- Links --> */}
          <h6 class="text-uppercase fw-bold mb-4">
            products
          </h6>
          <p>
            <a href="/estimates" class="text-reset">Estimates</a>
          </p>
          <p>
            <a href="/" class="text-reset">Maintenance</a>
          </p>
        </div>
        {/* <!-- Grid column --> */}
 {/* <!-- Grid column --> */}
 <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
          {/* <!-- Links --> */}
          <h6 class="text-uppercase fw-bold mb-4">
            Useful links
          </h6>
          <p>
            <a href="https://www.yepsplumbing.com" class="text-reset">Home</a>
          </p>
          <p>
            <a href="https://www.yepsplumbing.com/services.html" class="text-reset">Services</a>
          </p>
          <p>
            <a href="https://www.yepsplumbing.com/estimates" class="text-reset">Estimates</a>
          </p>
          <p>
            <a href="https://www.yepsplumbing.com/maintenance.html" class="text-reset">Maintenance</a>
          </p>
          <p>
            <a href="https://www.yepsplumbing.com/contact" class="text-reset">Contact Us</a>
          </p>
        </div>
        {/* <!-- Grid column --> */}
        
        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
          {/* <!-- Links --> */}
          <h6 class="text-uppercase fw-bold mb-4">Contact</h6>
          {/* <p><i class="fas fa-home me-3"></i> New York, NY 10012, US</p> */}
          <p>
            <i class="fas fa-envelope me-3 "></i>
           Email: yepsplumbing@gmail.com
          </p>
          <p><i class="fas fa-phone me-3 "></i>
          <a href="tel:+233 24 957 5016" class="text-reset">
           Phone: +233 24 957 5016
          </a>
           </p>
          
          <p><i class="fas fa-phone me-3"></i> <a
            href="tel:+233 56 111 1716" class="text-reset"> Phone: +233 56 111 1716</a></p>
        </div>
        {/* <!-- Grid column --> */}
      </div>
      {/* <!-- Grid row --> */}
    </div>
  </section>
  {/* <!-- Section: Links  --> */}

  {/* <!-- Copyright --> */}
  <div class="text-center p-4" style={{backgroundColor:'rgba(0, 0, 0, 0.05)'}}>
    © 2022 Copyright: Powered By Ultranexum
  </div>
  {/* <!-- Copyright --> */}
</footer>
{/* <!-- Footer --> */}
      </div>
      {/* end */}
    </div>
  );
}

export default Footer;
