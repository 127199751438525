import "./App.css";
import Home from "./Home";
import Nav from "./Nav";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import ScrollToTop from "./ScrollToTop";
import Footer from "./Footer";
import Estitmates from "./Estitmates";

function App() {
  return (
    <Router>
      <div className="app">
        <ScrollToTop/>
        <Switch>
          <Route path="/estimates"> 
            <Nav />
            <Estitmates />
            <Footer/>
          </Route>
          <Route path="/contact"> 
            <Nav />
            <Contact />
            <Footer/>
          </Route>
          <Route path="/services">
            {/* <Nav /> */}
            <About />
          </Route> 
          <Route path="/home">
            <Nav />
            <Home />
            <Footer/>
          </Route>
          <Route path="/">
            <Nav/>
            <Home />
            <Footer/>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
